import React, { useRef } from 'react';
// { useState, useEffect } from 'react';
import "../../styles/IntroSection.scss";
// import WAVES from 'vanta/dist/vanta.waves.min'

function IntroSection() {
    // Declare variables
    // const [vantaEffect, setVantaEffect] = useState(0)
    // const myRef = useRef(null)
    // useEffect(() => {
    //     if (!vantaEffect) {

    //         setVantaEffect(WAVES({
    //             el: myRef.current,
    //             mouseControls: true,
    //             touchControls: true,
    //             gyroControls: false,
    //             minHeight: 200.00,
    //             minWidth: 200.00,
    //             scale: 1.00,
    //             scaleMobile: 1.00,
    //             color: 0x1D4754,
    //             shininess: 29.00,
    //             waveHeight: 18.50,
    //             waveSpeed: 0.75
    //         }))

    // setVantaEffect(NET({
    //     el: myRef.current,
    //     mouseControls: true,
    //     touchControls: true,
    //     gyroControls: false,
    //     minHeight: 200.00,
    //     minWidth: 200.00,
    //     scale: 1.00,
    //     scaleMobile: 1.00,
    //     color: 0xC5C5C5,
    //     backgroundColor: 0x1D4754,
    //     points: 10.00
    // }))

    // setVantaEffect(TOPOLOGY({
    //     el: myRef.current,
    //     mouseControls: true,
    //     touchControls: true,
    //     gyroControls: false,
    //     minHeight: 200.00,
    //     minWidth: 200.00,
    //     scale: 1.00,
    //     scaleMobile: 1.00,
    //     color: 0xfafafa,
    //     backgroundColor: 0x1D4754
    // }))
    //     }
    //     return () => {
    //         if (vantaEffect) vantaEffect.destroy()
    //     }
    // }, [vantaEffect])

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const scrollToRef = (ref) => {
        const bottom = ref.current.offsetTop + ref.current.offsetHeight - 40
        window.scrollTo(0, bottom)
    }

    return (
        <div className="introSection" >
            <div className="text-content">
                <h3>Software Development Company</h3>
                <h5>EBS is a software development company that builds custom software for solving and turning business problems into  a user-friendly, interactive and scalable applications.</h5>
            </div>

            <div ref={myRef} className="bottomCut"></div>

            <img
                className="downArrow"
                src={require("../../assets/down-arrow.svg")}
                alt="arrow"
                onClick={executeScroll}
            />
        </div>
    );
}

export default IntroSection;
