import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/EBS.svg"
import "../../styles/NavBar.scss";

function NavBar() {
    const [navBackground, setNavBackground] = useState(0)
    const navRef = useRef()

    navRef.current = navBackground

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 70) {
                setNavBackground(1)

                if (window.innerWidth < 767) {
                    setNavBackground(2)
                }
            }
            else {
                setNavBackground(0)
            }
        }

        document.addEventListener('scroll', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
        }

    }, [])

    return (
        <header className={navBackground === 1 ? 'EBS-header scroll' : navBackground === 2 ? 'EBS-header mobile' : 'EBS-header'}
            style={{ transition: '0.7s ease' }} >
            <Navbar collapseOnSelect expand="md">
                <Navbar.Brand href="/">
                    <img className="EBS-logo" src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="ml-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/work"> Our Work </Nav.Link>
                        <Nav.Link href="/contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}

export default NavBar;
