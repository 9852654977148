import React from "react";
import IntoSection from "./IntroSection";
import AllWork from "./AllWork";
import "../../styles/Main.scss";

function Workpage() {
    return (
        <div>
            <IntoSection />
            <AllWork />
        </div>
    );
}

export default Workpage;