import React from 'react';

function ServiceCard(props) {
    return (
        <div className="serviceCard">
            <div>
                <img src={require("../../assets/" + props.icon + ".svg")} alt="icon" />
                <h3>{props.title}</h3>
                <p>{props.details}</p>
            </div>
        </div>
    );
}

export default ServiceCard;
