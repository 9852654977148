import React from "react";
import "../../styles/Main.scss";
import IntoSection from "./IntroSection";
import ServicesSection from "./ServicesSection";
import AchievementSection from "./AchievementSection";
import RecentWorkSection from "./RecentWorkSection";

function Homepage() {
    return (
        <div>
            <IntoSection />
            <ServicesSection />
            <AchievementSection />
            <RecentWorkSection />
        </div>
    );
}

export default Homepage;

//TODO:
// - numbers not loaded until scrolled to
// - navbar burger icon when scrolled
