import React from "react";
import IntoSection from "./IntroSection";
import ContactForm from "./ContactForm";
import "../../styles/Main.scss";

function Contactpage() {
    return (
        <div>
            <IntoSection />
            <ContactForm />
        </div>
    );
}

export default Contactpage;