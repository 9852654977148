import React, { useState } from "react";
import "../../styles/ContactForm.scss";
import axios from 'axios';

var link;
if (process.env.NODE_ENV === "production")
    link = 'https://ebs-web.herokuapp.com/api/';
else 
    link = `http://localhost:5000/api/`;


function ContactForm() {
    const [name, setName] = useState(0)
    const [phone, setPhone] = useState(0)
    const [email, setEmail] = useState(0)
    const [message, setMessage] = useState(0)
    async function handleSubmit() {
        // handle submit
        const body = { subject: '', text: '' }
        body.subject = 'Contact Me'
        body.text = `Name: ${name} \nEmail: ${email} \nPhone: ${phone} \n${message}`
        const res = await axios.post(`${link}sendMail`, body)
        console.log(res.data.data)
    }
    return (
        <div className="contactForm">
            <div className="outerBox">
                <div className="contactInfo">
                    <ul>
                        <li>Address: Cairo, Egypt</li>
                        <li>Email: info@ebs-lab.com</li>
                        <li>Phone numbers: +201064532222, +201000054457, +201000054456</li>
                    </ul>
                </div>

                <input placeholder="Your Name" name="name" onChange={(e) => setName(e.nativeEvent.target.value)}></input>
                <input placeholder="Your Phone" required type="tel" name="number" onChange={(e) => setPhone(e.nativeEvent.target.value)}></input>
                <input placeholder="Your E-mail" required type="email" name="email" onChange={(e) => setEmail(e.nativeEvent.target.value)}></input>
                <textarea placeholder="Your Message" type="text" rows="4" name="message" onChange={(e) => setMessage(e.nativeEvent.target.value)}></textarea>
                <button onClick={handleSubmit.bind(this)}>Send</button>
            </div>
        </div>
    );
}

export default ContactForm;