import React, { useRef, useState, useEffect } from 'react';
import { useCountUp } from 'react-countup';


function AchievementCard(props) {
    // Declare variables
    const { countUp, start } = useCountUp({ duration: 5, end: props.number, startOnMount: false })
    const [reached, setReached] = useState(false);
    const myRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // console.log(currentScrollY);
            if (currentScrollY >= (myRef.current.offsetTop + window.innerHeight * 1.3) && !reached) {
                setReached(true)
                start();
            }
        };
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        <div ref={myRef} className="achievementCard" onClick={() => start()}>
            <h1 className="number">{countUp}</h1>
            <h4 className="desciption">{props.description}</h4>
            <p className="text">{props.text}</p>
        </div>
    );
}

export default AchievementCard;
