import React, { useRef } from 'react';
import "../../styles/IntroSection.scss";

function IntroSection() {
    // Declare variables

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const scrollToRef = (ref) => {
        const bottom = ref.current.offsetTop + ref.current.offsetHeight - 70
        window.scrollTo(0, bottom)
    }

    return (
        <div className="introSection" >
            <div className="text-content">
                <h3>Contact us</h3>
                <h5>Need custom software?</h5>
                <h5>Can help you achieve your technical goals, drop us a line below.</h5>
                <h5>We will get back to you as quickly as possible.</h5>
            </div>

            <div ref={myRef} className="bottomCut"></div>

            <img
                className="downArrow"
                src={require("../../assets/down-arrow.svg")}
                alt="arrow"
                onClick={executeScroll}
            />
        </div>
    );
}

export default IntroSection;
