import React from 'react';
import "../../styles/Footer.scss";

function Footer() {
    // Declare variables

    return (
        <footer className="footer">
            <div className="footer-text">
                <div className="links">
                    <h3>Our Links</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/work">Our Work</a></li>
                        <li><a href="/contact">Contact us</a></li>
                        <div className="social-div">
                            <a
                                className="socialIcons"
                                href="https://www.linkedin.com/company/electronic-business-solutions-egy/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="social-icon fab fa-linkedin fa-2x"></i>
                            </a>
                            <a
                                className="socialIcons"
                                href="https://www.facebook.com/ebslab"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="social-icon fab fa-facebook-square fa-2x"></i>
                            </a>
                        </div>
                    </ul>
                </div>
                <div className="contactInfo">
                    <h3>Contact Information</h3>
                    <ul>
                        <li>Address: Cairo, Egypt</li>
                        <li>Email: info@ebs-lab.com</li>
                        <li>Phone number: +201064532222</li>
                        <li>Phone number: +201000054457</li>
                        <li>Phone number: +201000054456</li>
                    </ul>
                </div>
                <div className="newsletter">
                    <h3>Newsletter</h3>
                    <ul>
                        <li>Subscribe to our news letter</li>
                        <input placeholder="Your E-mail"></input>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
