import React, { useState } from 'react';
import ServiceCard from "../layout/ServiceCard";
import Slide from "react-reveal/Slide";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../styles/ServicesSection.scss";

function ServicesSection(props) {
    // Declare variables

    const responsive = {
        superLargeDesktop: {
            breakpoint: { min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1050 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1049, min: 666 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 666, min: 0 },
            items: 1
        }
    };

    const screen = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 3000)
            return "superLargeDesktop";
        else if (screenWidth >= 1050 && screenWidth < 3000)
            return "desktop"
        else if (screenWidth >= 666 && screenWidth < 1049)
            return "tablet"
        else if (screenWidth < 666)
            return "mobile"
    }

    const [myScreenWidth] = useState(screen());

    return (
        <Slide bottom>
            <div className="services">
                <h6 className="small-section-title">our services</h6>
                <h3>Turning your great ideas into successful solutions... </h3>
                <div className="services-container" onClick={screen}>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        focusOnSelect={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={500}
                        keyBoardControl={true}
                        customTransition="transform 1000ms ease-in-out"
                        transitionDuration={1000} //decrease .. makes faster
                        containerClass="carousel-container"
                        deviceType={myScreenWidth}
                        centerMode={false}
                    >
                        <ServiceCard
                            icon="Online Shopping-Shopping-Smartphone-Shopping Bag-Commerce"
                            title="E-Commerce Platforms"
                            details="Multifunctional eCommerce software development (online stores, auctions, marketplaces, etc.) for 82B and 82C sectors. Other Solutions We Vlake."
                        />
                        <ServiceCard
                            icon="saas"
                            title="Saas Solutions"
                            details="Analyzing and engineering custom scalable Saas products with 3rd party services integration"
                        />
                        <ServiceCard
                            icon="product-release"
                            title="Product Development"
                            details="Full support at each stage of product development, prototyping, MVP and product scaling."
                        />
                        <ServiceCard
                            icon="mobile-app"
                            title="Mobile Development"
                            details="IOS and Android apps built with React Native and Node.js will satisfy every end-user"
                        />
                        <ServiceCard
                            icon="ux-design"
                            title="UI/UX Design"
                            details="Jaw-dropping, functional interfaces designed from the ground up. \n Also, UX audits and workshops."
                        />
                        <ServiceCard
                            icon="script"
                            title="Desktop Development"
                            details="Amazing lightning-fast desktop applictions made with ...."
                        />
                        <ServiceCard
                            icon="data"
                            title="Web development"
                            details="Highly scalable platforms and well-tested products that are ready to handle millions of users daily"
                        />
                        <ServiceCard
                            icon="avatar"
                            title="Tech & Agile consulting"
                            details="Expert guidance on technology architecture. strategic planning, QA, design and delivery."
                        />
                        <ServiceCard
                            icon="building"
                            title="Enterprise Software"
                            details="Development of custom solutions, including robust ERR, CRM, HRM, automated billing and payment systems."
                        />
                        <ServiceCard
                            icon="rocket"
                            title="Startup Applications"
                            details="Planning, design and developing MVPs for startups and small businesses ready to enter the market."
                        />
                    </Carousel>
                </div>
            </div>
        </Slide>
    );
}

export default ServicesSection;
