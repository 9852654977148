import React from "react";
import WorkCard from "../layout/WorkCard";
import "../../styles/AllWork.scss";

function AllWork() {
    return (
        <div className="allWork">
            <WorkCard
                img="project1.png"
                title="EBS website"
                description="EBS is a leading software development company, delivering seamless functionality using cutting edge technologies. It is a development company that builds custom software to solve business problems. "
                minHeight="72vh"
                height="14vh"
                width="17vw"
            />
            <WorkCard
                img="project2.png"
                title="ERP-System"
                description="Our product is a business process management software that allows an organization to use a system of integrated applications to manage the business and automate many back office functions related to technology, services and human resources."
                direction="right"
                minHeight="72vh"
                height="34vh"
                width="17vw"
            />
            <WorkCard
                img="project3.svg"
                title="ProClubs"
                description="ProClubs aims to create a professional lifestyle for the football players who are not playing for any clubs. That is going to be done by creating the opportunity for every football Player to build up his own team and his own profile and a sort of small market without money."
                minHeight="72vh"
                height="54vh"
                width="17vw"
            />
        </div>
    );
}

export default AllWork;
