import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NavBar from "./components/layout/NavBar";
import Homepage from "./components/MainPage/Homepage";
import Workpage from "./components/WorkPage/Workpage";
import Contactpage from "./components/ContactPage/Contactpage";
import Footer from "./components/layout/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <NavBar />
          <Switch>
            <Route exact path="/" render={(props) => <Homepage {...props} />} />
            <Route exact path="/work" render={(props) => <Workpage {...props} />} />
            <Route exact path="/contact" render={(props) => <Contactpage {...props} />} />
          </Switch>

          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
