import React from 'react';
import AchievementCard from "../layout/AchievementCard";
import "../../styles/AchievementSection.scss";

function AchievementSection() {
    // Declare variables

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         //do the numbers thing
    //     }, 1000);
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className="achievementSection">
            {/* <div className="topCut"></div> */}

            <h2 className="section-title">Our Achievements</h2>

            <div className="achievementsContainer">
                <AchievementCard
                    number="73"
                    description="Finished Projects"
                    text="we implemented"
                />
                <AchievementCard
                    number="41"
                    description="Satisfied Customers"
                    text="with our professional support"
                />
                <AchievementCard
                    number="23"
                    description="Hosted Websites"
                    text="by EBS"
                />
            </div>

            <div className="bottomCut"></div>
        </div>
    );
}

export default AchievementSection;
