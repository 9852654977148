import React, { useRef } from 'react';
import "../../styles/IntroSection.scss";

function IntroSection() {
    // Declare variables

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const scrollToRef = (ref) => {
        const bottom = ref.current.offsetTop + ref.current.offsetHeight - 20
        window.scrollTo(0, bottom)
    }

    return (
        <div className="introSection workIntroSection" >
            <div className="text-content">
                <h3>Our Work</h3>
                <h5>While we cherish great design, web applications are more than slick graphics and static pages.
                Partner with our team of web design and web development experts on your next landmark web project.
                We deliver web applications that are reliable, secure, user-friendly, and incorporate a modern and professional design.</h5>

            </div>
            <div ref={myRef} className="bottomCut"></div>

            <img
                className="downArrow"
                src={require("../../assets/down-arrow.svg")}
                alt="arrow"
                onClick={executeScroll}
            />
        </div>
    );
}

export default IntroSection;
