import React from 'react';

function AchievementCard(props) {
    // Declare variables
    const styles = {
        minHeight: props.minHeight || '50vh'
    };

    const imgStyles = {
        minHeight: props.height || '10vw',
        minWidth: props.width || '35vw'
    };

    return (
        <div className={`workCard ${props.direction}`} style={styles}>
            <div className="photos" >
                <img src={require("../../assets/" + props.img)} alt="work" style={imgStyles} />
            </div>
            <div className="text">
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
        </div>
    );
}

export default AchievementCard;
